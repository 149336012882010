import { LinkTarget } from '@/types'

export const LOGIN_ATTEMPT_KEY = 'login-attempt'

export const DEVELOPERS_LANDPAGE_PATH = '/developers'

export const PRIVACY_POLICY_NAVIGATION_ITEM = {
    title: 'privacy_policy',
    link: '/confidential',
}

type NavLink = {
    // TODO: type title, link to safely use our routes/route-names
    title: string
    link: string
    external?: boolean
    target?: LinkTarget
}

export const NAVIGATION_LINKS: NavLink[] = [
    {
        title: 'developers',
        link: DEVELOPERS_LANDPAGE_PATH,
        target: '_blank',
        external: true, // Do not remove. This route is not in the Vue app
    },
    {
        title: 'advertisers',
        link: '/advertisers',
    },
    {
        title: 'publishers',
        link: '/publishers',
    },
    PRIVACY_POLICY_NAVIGATION_ITEM,
    {
        title: 'contact_us',
        link: '/contacts',
    },
    {
        title: 'takedown_notice',
        link: '/takedown-notice',
    },
]

export const CUSTOM_PG_EVENTS = ['game_opened', 'session_start'] as const
export const DEFAULT_CLIDS = { direct: 'direct', directTest: 'direct-test' }
