import type { OutOfPageAdType, PageAdType } from '../adv'

export type YaPlatformType = 'desktop' | 'touch'

export type FormatType = {
    blockId: string
    type?: 'rewarded' | 'fullscreen'
    platform: YaPlatformType
} | null

export const formats: Record<OutOfPageAdType | PageAdType, Partial<Record<YaPlatformType, FormatType>>> = {
    rewarded: {
        desktop: {
            blockId: 'R-A-4580605-1',
            type: 'rewarded',
            platform: 'desktop',
        },
        touch: {
            blockId: 'R-A-4580605-2',
            type: 'rewarded',
            platform: 'touch',
        },
    },
    interstitial: {
        desktop: {
            blockId: 'R-A-4580605-4',
            type: 'fullscreen',
            platform: 'desktop',
        },
        touch: {
            blockId: 'R-A-4580605-3',
            type: 'fullscreen',
            platform: 'touch',
        },
    },
    fullscreen: {
        desktop: {
            blockId: 'R-A-4580605-4',
            type: 'fullscreen',
            platform: 'desktop',
        },
        touch: {
            blockId: 'R-A-4580605-3',
            type: 'fullscreen',
            platform: 'touch',
        },
    },
    sidebar: {
        desktop: {
            blockId: 'R-A-4580605-5',
            platform: 'desktop',
        },
        touch: {
            blockId: 'R-A-4580605-5',
            platform: 'touch',
        },
    },
    sticky_portrait: {
        touch: {
            blockId: '',
            platform: 'touch',
        },
    },
    leaderboard: {
        desktop: {
            blockId: 'R-A-4580605-6',
            platform: 'desktop',
        },
        touch: {
            blockId: 'R-A-4580605-6',
            platform: 'touch',
        },
    },
    leaderboard_rest: {
        desktop: {
            blockId: 'R-A-4580605-6',
            platform: 'desktop',
        },
        touch: {
            blockId: 'R-A-4580605-6',
            platform: 'touch',
        },
    },
    catalog: {
        desktop: {
            blockId: 'R-A-4580605-6',
            platform: 'desktop',
        },
        touch: {
            blockId: 'R-A-4580605-6',
            platform: 'touch',
        },
    },
    catalog_mobile: {
        touch: {
            blockId: 'R-A-4580605-5',
            platform: 'touch',
        },
    },
    catalog_mobile_rest: {
        touch: {
            blockId: 'R-A-4580605-5',
            platform: 'touch',
        },
    },
}
