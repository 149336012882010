import { RouteLocationRaw } from 'vue-router'
import { useAppStore } from '@/store/app-store'

export function useUrlParams() {
    const URL_PARAMS_TO_KEEP = ['clid', 'autoplay']
    const { query } = useAppStore()
    function transfer(url: string | RouteLocationRaw): string | RouteLocationRaw {
        try {
            const currentParams = new URLSearchParams(query as Record<string, string>)
            const paramsToAdd = new URLSearchParams()

            currentParams.forEach((value, key) => {
                if (URL_PARAMS_TO_KEEP.includes(key)) {
                    paramsToAdd.set(key, value)
                }
            })

            if (typeof url === 'string') {
                return `${url}${paramsToAdd.size ? `?${paramsToAdd.toString()}` : ''}`
            } else {
                const resultQuery = url.query ? { ...url.query } : {}

                paramsToAdd.forEach((value, key) => {
                    resultQuery[key] = value
                })

                return {
                    ...url,
                    query: resultQuery,
                }
            }
        } catch {
            throw Error('Could not retrieve URL params')
        }
    }

    return { transfer }
}
