<template>
    <div v-if="show">
        <div
            id="g_id_onload"
            :data-client_id="VUE_APP_GAUTH_CLID"
            :data-login_uri="loginURL"
            data-auto_prompt="false"
        />
        <div
            :class="[$style.google_signin, 'g_id_signin']"
            data-type="standard"
            data-size="large"
            data-theme="outline"
            data-text="sign_in_with"
            data-shape="pill"
            data-logo_alignment="left"
        />
    </div>
</template>
<script lang="ts" setup>
import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { LOGIN_ATTEMPT_KEY } from '@/constants/general'
import { useUrlParams } from '@/composables/url-params'

const { VUE_APP_GAUTH_CLID, VUE_APP_GAUTH_REDIRECT } = process.env

const props = defineProps<{
    returnURL?: string
}>()

const route = useRoute()
const { transfer } = useUrlParams()

const loginURL = computed(() => {
    const url = props.returnURL ? transfer(props.returnURL) : route.fullPath
    const returnURL = `${window.location.origin}${url}`
    return `${VUE_APP_GAUTH_REDIRECT}?${new URLSearchParams({ returnURL })}`
})

onBeforeMount(() => {
    // This component is re-mounted every time it is needed, and we re-initialize
    // gsi client with new "data-login_uri" every time by inserting the script tag again and again
    // DO NOT optimize this by checking if the script is already loaded!
    // That is stipud, but is an easlier solution.
    // The problem is that gsi client rememebers only initial value of the "data-login_uri", and does not update it without
    // re-initialization, even though we change it in the DOM.
    const script = document.createElement('script')
    script.dataset.read_me = 'This script can be duplicated in DOM on purpose, find this message in sources'
    script.src = 'https://accounts.google.com/gsi/client'
    document.body.appendChild(script)
})

// show only on the client side
const show = ref(false)
onMounted(() => {
    show.value = true
    // Move to a store when additional ways to log in are added
    window.sessionStorage.setItem(LOGIN_ATTEMPT_KEY, 'true')
})
onBeforeUnmount(() => {
    window.sessionStorage.removeItem(LOGIN_ATTEMPT_KEY)
})
</script>
<style module>
.google_signin {
    color-scheme: light;
    height: 44px;
    overflow: hidden;
}
</style>
