import type {
    PaginationType,
    CategoryDataType,
    APIGameType,
    PlatformType,
    ShortGameType,
    GameRecommendationType,
    APICategoryDataType,
    APICategoryInfo,
    APIGameRecommendation,
} from '@/types'
import { request } from '@/utils/requests/request'

type GamesAndCategoriesRequestParams = {
    categoriesIds?: string[]
    platform: PlatformType
    pagination?: PaginationType
}

const DEFAULT_PAGINATION: PaginationType = {
    page: 1,
    pageSize: 1,
}

export async function getGamesByCategories({
    categoriesIds,
    platform,
    pagination = DEFAULT_PAGINATION,
}: GamesAndCategoriesRequestParams): Promise<CategoryDataType> {
    const path = '/api/v1/categories/games'
    const search = new URLSearchParams()
    search.set('pagination', JSON.stringify(pagination))
    search.set('platform', platform)
    if (categoriesIds) {
        search.set('categoriesIds', JSON.stringify(categoriesIds))
    }
    const { originalResponse, data } = await request(`${path}?${search}`)

    if (!originalResponse.ok) {
        return Promise.reject(originalResponse.status)
    }

    const gamesByCategories: APICategoryDataType = data
    return {
        categories: gamesByCategories.categories.map((category) => ({
            ...category,
            games: category.games,
        })),
        pagination: gamesByCategories.pagination,
    }
}

export async function getGameByParam(param: string): Promise<APIGameType> {
    const URL = `/api/v1/games/${param}`
    try {
        const { originalResponse, data } = await request<APIGameType>(URL)
        if (!originalResponse.ok) {
            // возможно логировать причину - непонятно
            return Promise.reject(originalResponse.status)
        }

        return data
    } catch (error) {
        console.error({ URL, error })
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(500)
    }
}

export async function getGamesCategoriesInfo(): Promise<APICategoryInfo[]> {
    const path = '/api/v1/categories/info'
    try {
        const { originalResponse, data } = await request<APICategoryInfo>(path)
        if (!originalResponse.ok) {
            return Promise.reject(originalResponse.status)
        }
        return data
    } catch (error) {
        console.error({ URL, error })
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(500)
    }
}

export async function getShortGames(hrus: string[]): Promise<ShortGameType[]> {
    const URL = `/api/v1/games/simplified?hrus=${hrus.join(',')}`
    try {
        const { originalResponse, data } = await request<ShortGameType>(URL)
        if (!originalResponse.ok) {
            // возможно логировать причину - непонятно
            return Promise.reject(originalResponse.status)
        }

        return data
    } catch (error) {
        console.error({ URL, error })

        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(500)
    }
}

export async function getGameRecommendations<T extends GameRecommendationType[]>({
    hru,
    types,
    platform,
    limit = 32,
}: {
    hru: string
    types: T
    platform: PlatformType
    limit?: number
}): Promise<APIGameRecommendation<T>> {
    const search = new URLSearchParams()
    search.set('rec_types', types.join(','))
    search.set('limit', String(limit))
    search.set('platform', platform)

    const URL = `/api/v1/games/recommendations/${hru}?${search}`
    try {
        const { originalResponse, data } = await request(URL)
        if (!originalResponse.ok) {
            // возможно логировать причину - непонятно
            return Promise.reject(originalResponse.status)
        }

        return data
    } catch (error) {
        console.error({ URL, error })
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject(500)
    }
}
